<template>
  <ul class="menu-nav" v-if="currentUser.access_type!=''">
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Contacts </span>
      </a>

      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link v-if="checkIsAccessible('account_holder')"
                             :to="{ name: 'accountholder' }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-users"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Enrollers</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'examiner' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-user-graduate"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Examiners</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{ name: 'online-marker' }" v-if="currentUser.access_type!='score'"
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">

                      <i class="menu-icon fas fa-user-shield"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Markers </span>

                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'supervisor' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-user-clock"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Supervisors </span>
                    </a>
                  </li>
                
                </router-link>
                
                <router-link
                    :to="{ name: 'candidates' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-user"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Candidates </span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{name:'school-account'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-file-invoice"></i>
                      <span class="menu-text">Schools</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'address-book' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-address-book"
                      ><span></span
                      ></i>
                      <span class="menu-text"> External contacts </span>
                    </a>
                  </li>
                </router-link>
                <router-link to="/children-check" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-child"></i>
                      <span class="menu-text">Child safety</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'candidate-unique-number' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-user-secret"
                      ><span></span
                      ></i>
                      <span class="menu-text"> UniqueIDs </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    v-if="appEnv != 'production'"
                    :to="{ name: 'unique-members' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-user"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Unique member </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Enrolments</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    :to="{ name: 'candidate-type', params:{type: 'practical'} }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-chalkboard-teacher"></i>
                      <span class="menu-text">Practical exams</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'candidate-type', params: {type:'exam'} }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-computer"></i>
                      <span class="menu-text">Theory exams</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'candidate-type', params:{type:'course'} }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-laptop-file"></i>
                      <span class="menu-text">Theory courses</span>
                    </a>
                  </li>
                </router-link>

                <router-link :to="{ name: 'candidate-type', params: {type:'ameb-award'} }" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-medal"></i>
                      <span class="menu-text">AMEB award</span>
                    </a>
                  </li>
                </router-link>

                <router-link :to="{ name: 'candidate-type', params: {type:'teaching_video_series'} }" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-video"></i>
                      <span class="menu-text">Teaching video series</span>
                    </a>
                  </li>
                </router-link>

                <router-link :to="{ name: 'manual-enrolment-step-1' }" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-database"><span></span></i>
                      <span class="menu-text">Manual enrolment</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{name: 'fed-registration'}"  v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-gift"></i>
                      <span class="menu-text"> Comp enrolment</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{name:'exam-day'}" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-calendar-day"><span></span></i>
                      <span class="menu-text">Schedules</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{name:'start-scheduling'}" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-hourglass-start"><span></span></i>
                      <span class="menu-text">Start scheduling</span>
                    </a>
                  </li>
                </router-link>

                <router-link :to="{name:'pending-timetable-print'}" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-print"><span></span></i>
                      <span class="menu-text">Timetable printing</span>
                    </a>
                  </li>
                </router-link>

                <router-link :to="{name:'pending-print'}" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-print"><span></span></i>
                      <span class="menu-text">Printing</span>
                    </a>
                  </li>
                </router-link>

                <router-link to="/print-queue" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-grip-lines"></i>
                      <span class="menu-text">Print queue</span>
                    </a>
                  </li>
                </router-link>
                <router-link  :to="{ name: 'shipment-certificate-request-waiting' }" v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-certificate"></i>
                      <span class="menu-text">International certificate requests</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <!-- Results -->
<!--    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Results & reports</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    :to="{ name: 'candidate-practical-exam-result' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fa fa-line-chart"></i>
                      <span class="menu-text">Practical</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'candidate-online-exam-result'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-bar-chart"></i>
                      <span class="menu-text">Online exams</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'candidate-online-course-result' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fa-solid fa-chart-bar"></i>
                      <span class="menu-text">Courses</span>
                    </a>
                  </li>
                </router-link>


              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>-->
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Exams setup</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    :to="{ name: 'product' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon flaticon2-list-2"><span></span></i>
                      <span class="menu-text">Exams</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'dedicated-price' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fab flaticon-price-tag"><span></span></i>
                      <span class="menu-text"> Pricing</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'grades' }"
                    v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fas fa-list-ol"><span></span></i>
                      <span class="menu-text">Grades</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'category' }"
                    v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-list"><span></span></i>
                      <span class="menu-text">Categories</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'instrument' }"
                    v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-music"><span></span></i>
                      <span class="menu-text">Instrument families</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'syllabus' }"
                    v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-book-reader"><span></span></i>
                      <span class="menu-text">Syllabuses</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'exam-type' }"
                    v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fa fa-box-open"><span></span></i>
                      <span class="menu-text">Types</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'exam-options' }"
                    v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-prescription-bottle"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Options</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'comment-banks' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-comment"
                      ><span></span
                      ></i>
                      <span class="menu-text"> Comment banks</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{name: 'collaborative-instrument-category'}"
                    v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-calendar-week"><span></span></i>
                      <span class="menu-text"> Collaborative instruments category</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{name: 'collaborative-instrument'}"
                    v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-calendar-week"><span></span></i>
                      <span class="menu-text"> Collaborative instruments </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{name: 'exam-session'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-calendar-week"><span></span></i>
                      <span class="menu-text"> Exam {{ currentUser.session_default_text }} </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'location' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-map-marker-alt"></i>
                      <span class="menu-text">Locations</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'venue' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-street-view"></i>
                      <span class="menu-text">Venues</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'room-attribute' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-layer-group"></i>
                      <span class="menu-text">Room features</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <!--    settings-->
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Settings</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    :to="{ name: 'score' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item"
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-globe"></i>
                      <span class="menu-text">Local settings</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{name: 'setting'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"  v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-cog"><span></span></i>
                      <span class="menu-text">Configurations </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'scheduling-request' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-clipboard-list"></i>
                      <span class="menu-text">Scheduling requests</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'enrolment-year' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"  v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-calendar-day"></i>
                      <span class="menu-text">Years</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'transaction-heading' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-file-invoice"></i>
                      <span class="menu-text">Custom fees</span>
                    </a>
                  </li>
                </router-link>
                <router-link to="/admin-user" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-users"></i>
                      <span class="menu-text">Admin access</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{name:'support-category'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-clipboard-check"></i>
                      <span class="menu-text">Support categories</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{name: 'email-signature'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"  v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-signature"><span></span></i>
                      <span class="menu-text">Email signature generator </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <!--    CMS-->
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Content</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    :to="{ name: 'email-template-roles' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-envelope"></i>
                      <span class="menu-text">Email template</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'blog' }"
                    v-slot="{ href, navigate, isActive, isExactActive }" 
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-bars"></i>
                      <span class="menu-text">What's new</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="currentUser.access_type!='score'"
                  :to="{ name: 'faq' }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-question-circle"></i>
                      <span class="menu-text">FAQs</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'page' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"  v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon flaticon2-copy"></i>
                      <span class="menu-text">Page content</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'download' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-download"></i>
                      <span class="menu-text">Downloads</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'popup' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"  v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-external-link-alt"></i>
                      <span class="menu-text">Pop ups</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{name: 'enrol-options'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"  v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-globe"><span></span></i>
                      <span class="menu-text">Enrol buttons</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'exam-software' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"  v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-desktop"></i>
                      <span class="menu-text">Exam software</span>
                    </a>
                  </li>
                </router-link>



<!--                <router-link-->
<!--                    :to="{ name: 'notice' }"-->
<!--                    v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--                >-->
<!--                  <li-->
<!--                      aria-haspopup="true"-->
<!--                      class="menu-item"-->
<!--                      :class="[-->
<!--                          isActive && 'menu-item-active',-->
<!--                          isExactActive && 'menu-item-active',-->
<!--                        ]"-->
<!--                  >-->
<!--                    <a :href="href" class="menu-link">-->
<!--                      <i class="menu-icon fas fa-bullhorn"></i>-->
<!--                      <span class="menu-text">Notices</span>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                </router-link>-->

                <router-link
                    :to="{ name: 'interest' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"  v-if="currentUser.access_type!='score'"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-external-link-alt"></i>
                      <span class="menu-text">What's your interest</span>
                    </a>
                  </li>
                </router-link>
            </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }" v-if="currentUser.access_type!='score'"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Marking</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link  :to="{ name: 'ongoing-official-exams' }" v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon far fa-calendar-alt"></i>
                      <span class="menu-text">Open exams</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'needs-marking' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-file-invoice"></i>
                      <span class="menu-text">For marking</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'in-progress-marking' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-chart-area"></i>
                      <span class="menu-text">In progress</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'completed-marking' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-calendar-day"></i>
                      <span class="menu-text">Marked</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'all-completed-marking' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-clipboard-list"></i>
                      <span class="menu-text">Completed</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Tickets 
          <span class="badge badge-square badge-primary ml-1 count-badge-nav">{{ unReadTicketAll+$store.getters.countSchoolPayApplications }}</span>
        </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    :to="{ name: 'support-ticket', params: { type: 'all' } }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-envelope"></i>
                      <span class="menu-text">View tickets 
                        <span class="badge badge-square badge-primary ml-1 count-badge-nav">{{ unReadTicketAll }}</span>
                      </span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{name:'support-online-exam'}" v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fa fa-desktop"></i>
                      <span class="menu-text">Online candidate tickets</span>
                    </a>
                  </li>
                </router-link>
<!--                <router-link to="/my-ticket-assigned" v-slot="{ href, navigate, isActive, isExactActive }">-->
<!--                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">-->
<!--                    <a :href="href" class="menu-link" @click="navigate">-->
<!--                      <i class="menu-icon fas fa-tags"></i>-->
<!--                      <span class="menu-text">My tickets <span class="badge badge-square badge-primary ml-1 count-badge-nav">{{$store.getters.countAssignedTicket}}</span></span>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                </router-link>-->
                <router-link
                    :to="{name:'school-pay-application', params: {application_status: 'pending'} }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-school"></i>
                      <span class="menu-text">SchoolPay applications <span class="badge badge-square badge-primary ml-1 count-badge-nav">{{$store.getters.countSchoolPayApplications}}</span></span>
                    </a>
                  </li>
                </router-link>
                <router-link  :to="{ name: 'appeal-request-online-exam' }" v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon far fas fa-thumbtack"></i>
                      <span class="menu-text">Appeals</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
          </div>
      </div>
    </li>
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Activity <span class="badge badge-square badge-primary ml-1 count-badge-nav ">{{$store.getters.countAccountHolder + $store.getters.countCandidate + $store.getters.countUnlockRequests + $store.getters.countVenueRequests}}</span></span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link
                    :to="{ name: 'accountholder-update-requests'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-user-edit"></i>
                      <span class="menu-text">Profile edits<span class="badge badge-square badge-primary ml-1 count-badge-nav">{{$store.getters.countAccountHolder}}</span></span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'candidate-update-requests'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-user-edit"></i>
                      <span class="menu-text">Candidate detail edits <span class="badge badge-square badge-primary ml-1 count-badge-nav">{{$store.getters.countCandidate}}</span></span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{ name: 'unlock-report-requests' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fa fa-unlock-alt"></i>
                      <span class="menu-text">Unlock requests
                      <span class="badge badge-square badge-primary ml-1 count-badge-nav">{{$store.getters.countUnlockRequests}}</span></span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'venue-update-requests'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-map-marker"></i>
                      <span class="menu-text">Venue edits 
                      <span class="badge badge-square badge-primary ml-1 count-badge-nav">{{$store.getters.countVenueUpdateRequests}}</span></span>
                    </a>
                  </li>
                </router-link>

                <router-link
                    :to="{ name: 'venue-request' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-map-marker-alt"></i>
                      <span class="menu-text">Private venue requests <span class="badge badge-square badge-primary ml-1 count-badge-nav">{{$store.getters.countVenueRequests}}</span> </span>
                    </a>
                  </li>
                </router-link>

<!--                <router-link
                    to="#"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fa fa-copy"></i>
                      <span class="menu-text">UID duplicates</span>
                    </a>
                  </li>
                </router-link>-->

              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li

        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Finance</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link :to="{name: 'invoice'}"  v-slot="{ href, navigate, isActive, isExactActive }" >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-file-invoice"></i>
                      <span class="menu-text">Invoices</span>
                    </a>
                  </li>
                </router-link>
                <router-link  v-if="currentUser.access_type!='score'"
                    :to="{name:'school-pay-pending-invoice'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-file-invoice"></i>
                      <span class="menu-text">Pending invoices</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                    :to="{name:'pending-cart'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-shopping-cart"></i>
                      <span class="menu-text">Pending cart</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-if="appEnv == 'local'"
                  :to="{name:'school-pay-invoices'}"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-file-invoice"></i>
                      <span class="menu-text">SchoolPay invoices</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{ name: 'shipment-certificate-request-report' }" v-slot="{ href, navigate, isActive, isExactActive }" v-if="currentUser.access_type!='score'">
                  <li class="menu-item" :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-truck-moving"></i>
                      <span class="menu-text">International certificate shipment report</span>
                    </a>
                  </li>
                </router-link>

                <router-link :to="{ name: 'sales-report'}" 
                    v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-bar-chart"></i>
                      <span class="menu-text">Sales report</span>
                    </a>
                  </li>
                </router-link>

                <router-link :to="{ name: 'refund-report'}" 
                    v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-dollar"></i>
                      <span class="menu-text">Refund report</span>
                    </a>
                  </li>
                </router-link>

                <router-link :to="{ name: 'state-sales-report'}" v-if="currentUser.access_type=='score' &&currentUser.score_id=='5'"
                    v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-chart-area"></i>
                      <span class="menu-text">Sales report (CSV)</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{ name: 'grading-finance-report'}" v-if="currentUser.access_type!='score'"
                    v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-pie-chart"></i>
                      <span class="menu-text">Grading report</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{ name: 'marking-report'}" v-if="currentUser.access_type!='score'"
                    v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fas fa-line-chart"></i>
                      <span class="menu-text">Marking report</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{ name: 'state-exam-report-report'}" v-if="currentUser.access_type=='score' "
                             v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-chart-area"></i>
                      <span class="menu-text">Exam report (CSV)</span>
                    </a>
                  </li>
                </router-link>
                <router-link :to="{name: 'coupon-code'}"  v-slot="{ href, navigate, isActive, isExactActive }">
                  <li aria-haspopup="true" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active',]">
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-tags"></i>
                      <span class="menu-text">Coupon codes</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>


  </ul>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "KTMenu",
  methods: {
    ...mapActions([
      'GET_STATS'
    ]),
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  mounted() {
    this.GET_STATS();
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    isAdmin() {
      return this.currentUser.type === "admin" ? true : false;
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
    unReadTicketAll() {
      return this.$store.getters.currentSupport;
    },
  },
};
</script>
